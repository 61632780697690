import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../../components/layout';

import OdysseyDanceImage from '../../assets/images/publication-banners/Odyssey-Dance.jpg';

import odysseyJSON from '../../content/season/odyssey-dance.json';

const OdysseyDance = (props) => (
  <Layout>
    <Helmet>
      <title>Odyssey Dance Theatre - Mills Publishing Inc.</title>
      <meta
        name="description"
        content="Advertising and event information for Odyssey Dance Theatre’s season playbills. Please contact Mills Publishing for availability and rates."
      />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>Odyssey Dance Theatre</h1>
          </header>
          <span className="image main">
            <img src={OdysseyDanceImage} alt="" />
          </span>
          <p className="box">
            Odyssey Dance Theatre offers a unique advertising opportunity
            through its dance programs. Every year, Thriller returns to rave
            reviews, providing you a chance to reach a high-grade, dedicated
            audience during the fall season.
          </p>
          <ul className="actions">
            <li>
              <a href="https://odysseydance.com/" className="button special">
                <i className="icon fa-globe"> </i>Odyssey Dance Theatre Website
              </a>
            </li>
          </ul>
          <h2>
            Odyssey Dance Theatre {new Date().getFullYear()} Season Information
          </h2>
          <p>
            <em>
              Dates are subject to change. Please contact us if you have any
              questions.
            </em>
          </p>
          <div className="grid-wrapper">
            {/* Calls the season json, accesses the array, and maps/creates a new array */}
            {odysseyJSON.odysseySeason.map((data, index) => {
              return (
                <div className="col-4 box" key={index}>
                  <img
                    style={{ width: 100 + '%' }}
                    src={data.image}
                    alt={data.alt}
                  />
                  <h3>{data.title}</h3>
                  <strong>{data.date}</strong> <br />
                  <p dangerouslySetInnerHTML={{ __html: data.desc }}></p>
                  <h5>Advertising Information</h5>
                  <section className="box">
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            File Ready Deadline: <br />
                            {data.fileReady}
                          </td>
                        </tr>
                        <tr>
                          <td>Circulation: {data.quantity}</td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default OdysseyDance;
